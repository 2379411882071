<template>
  <div id="newsStoryContent">
    <!-- pic3FA67CF9706BF5E1E147DC992E225331.jpg -->
    <img id="Image1" src="" class="rounded">
    <p id="Text1">
      From September 30th - October 3rd thousands of AFOLs (Adult Fans of LEGO®) will be showing
      off their impressive building skills, as well as sharing all their amazing creations with
      each other and the public, at BrickCon in Seattle.
      <br><br>
      We will be there together with our LUPs (LEGO Universe Partners) to take part in all the
      creative building festivities. And for the first time ever we are opening up the doors to
      the new imaginary worlds they have created in LEGO Universe.
      <br><br>
      Stop by our action-packed space for a hands-on building experience of your own. Build awesome
      creations by the LUPs building table as well as in the game, and discover the techniques to
      how the LUPs’ amazing worlds were brought to life in LEGO Universe.
    </p>
  </div>
</template>
